<template>
    <exterior-page-content-container>
        <div class="flex flex-col h-56 my-32 text-xl justify text-content">
            <p class="text-9xl">404</p>
            <p v-html="message"></p>
            <p>
                Click
                <a
                    @click="$router.push({name: 'Dashboard'})"
                    class="underline cursor-pointer"
                >
                here</a> to go back to the website!
            </p>
        </div>
    </exterior-page-content-container>
</template>

<script>
import ExteriorPageContentContainer from '@/components/shared/containers/ExteriorPageContentContainer.vue';

export default {
  name: 'PageNotFound',
  components: { ExteriorPageContentContainer },
  computed: {
    message() {
      const { page } = this.$route.params;
      if (page && page.toLowerCase() === 'project') {
        return 'Oh no!!! We can\'t find the <strong>Project</strong> you are looking for.';
      }
      if (page && page.toLowerCase() === 'room') {
        return 'Oh no!!! We can\'t find the <strong>Room</strong> you are looking for.';
      }
      if (page && page.toLowerCase() === 'item') {
        return 'Oh no!!! We can\'t find the <strong>Item</strong> you are looking for.';
      }
      return 'Oh no!!! Looks like we don\'t have the page you are looking for.';
    },
  },
};
</script>

<style>

</style>
